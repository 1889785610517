import React, {
  useState, useCallback, useEffect, createRef, memo, useMemo,
} from 'react'
import flow from 'lodash.flow'
import {
  Avatar,
  Button,
  TextField,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Skeleton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  TableContainer,
  Paper,
} from '@mui/material'
import {
  Clear as CancelIcon,
  Search as SearchIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as UpdateOutlineIcon,
  VpnKey as VpnKeyIcon,
  LockPerson as LockPersonIcon,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { ExportToCsv } from 'export-to-csv'
import { styled } from '@mui/material/styles'
import { TableVirtuoso } from 'react-virtuoso'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { Role } from '../../enums/Role'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import { generateInitialAzureAdPassword } from '../../util/PasswordUtils'
import TempPasswordDialog from '../../components/TempPasswordDialog'
import { withRouter } from '../../screenWrappers/withRouter'
import { appUserShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const RowText = styled('p')((
  {
    theme,
  }
) => ({
  fontSize: '.85em',
  '& .fas.fa-eye, .fas.fa-angle-double-right': {
    color: theme.palette.primary.main,
  },
}))

const HiddenSpan = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
})

const TableCellHeader = styled(TableCell)({
  minWidth: '1px',
  maxWidth: '400px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'sticky',
  top: 0,
  backgroundColor: '#fafafa',
  border: 'none',
  boxShadow: 'inset 0 -2px 0 #dddddd',
  padding: '2px 2px 2px 10px',
  zIndex: 2,
})

const TableCellHeaderGrouped = styled(TableCell)({
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '1px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'sticky',
  top: 0,
  backgroundColor: '#fafafa',
  border: 'none',
  padding: '2px 0',
  zIndex: 2,
})

const StyledTableCell = styled(TableCell)({
  maxWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  zIndex: 1,
})

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'boolean') {
    if (a[orderBy] === b[orderBy]) {
      return 0
    }
    return a[orderBy] ? 1 : -1
  }
  const aProperty = a[orderBy] ?? ''
  const bProperty = b[orderBy] ?? ''
  if (bProperty.toLowerCase() < aProperty.toLowerCase()) {
    return -1
  }
  if (bProperty.toLowerCase() > aProperty.toLowerCase()) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const filterByQuickSearchText = (arr, quickSearchText) => arr.filter(
  (e) => Object.keys(e).some(
    (field) => e[field] && JSON.stringify(e[field]).toLowerCase().includes(quickSearchText.toLowerCase())
  )
)

const filterByPermissionFilters = (arr, permissionFilters) => arr.filter((e) => Object.keys(permissionFilters)
  .filter((permission) => permissionFilters[permission])
  .some((permission) => e[permission]))

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  askForConfirmationListener: PropTypes.func.isRequired,
  showSnackbarSuccess: PropTypes.func.isRequired,
}

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{
        width: '100%',
      }}
      aria-labelledby="tableTitle"
      size="small"
      aria-label="enhanced table"
    />
  ),
  TableHead,
  // eslint-disable-next-line react/prop-types
  TableRow: ({ item: _item, ...props }) => (
    <TableRow
      sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0, 0.04)' } }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ),
  // eslint-disable-next-line react/jsx-props-no-spreading
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}

const headersPropTypes = {
  permissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.func.isRequired,
  })).isRequired,
  user: appUserShape.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape(
    { id: PropTypes.string.isRequired, label: PropTypes.string.isRequired }
  )).isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
}
function Headers({
  user, columns, permissions, orderBy, order, setOrder, setOrderBy,
}) {
  return (
    <>
      <TableRow>
        <TableCellHeaderGrouped
          colSpan={4}
          sx={{ minWidth: '60%', borderRight: '1px solid rgba(224, 224, 224, 1)' }}
        />
        <TableCellHeaderGrouped colSpan={user.pod === 'ABS' ? 6 : 4}>
          Permissions
        </TableCellHeaderGrouped>
      </TableRow>
      <TableRow>
        {columns.map((headCell) => (
          <TableCellHeader
            sx={{ width: '15%', resize: 'horizontal' }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {
                const isAsc = orderBy === headCell.id && order === 'asc'
                setOrder(isAsc ? 'desc' : 'asc')
                setOrderBy(headCell.id)
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <HiddenSpan>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </HiddenSpan>
              ) : null}
            </TableSortLabel>
          </TableCellHeader>
        ))}
        <TableCellHeader
          padding="normal"
          key="options"
          sx={{ minWidth: '15%', borderRight: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <div>
            <TableSortLabel
              hideSortIcon
            >
              Actions
            </TableSortLabel>
          </div>
        </TableCellHeader>
        {permissions.map((headCell) => (
          <TableCellHeader
            sx={{
              maxWidth: '70px', overflow: 'hidden', textWrap: 'wrap', textAlign: 'center',
            }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {
                const isAsc = orderBy === headCell.id && order === 'asc'
                setOrder(isAsc ? 'desc' : 'asc')
                setOrderBy(headCell.id)
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <HiddenSpan>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </HiddenSpan>
              ) : null}
            </TableSortLabel>
          </TableCellHeader>
        ))}

      </TableRow>
    </>
  )
}
Headers.propTypes = headersPropTypes

const rowPropTypes = {
  row: PropTypes.shape({
    '@odata.type': PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    userPrincipalName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
    dataadmin: PropTypes.bool.isRequired,
    useradmin: PropTypes.bool.isRequired,
    readonly: PropTypes.bool.isRequired,
    podowner: PropTypes.bool.isRequired,
    libraryadmin: PropTypes.bool.isRequired,
    billingadmin: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    avatarColor: PropTypes.string.isRequired,
    toggleInProgress: PropTypes.shape({
      admin: PropTypes.bool.isRequired,
      dataadmin: PropTypes.bool.isRequired,
      useradmin: PropTypes.bool.isRequired,
      readonly: PropTypes.bool.isRequired,
      podowner: PropTypes.bool.isRequired,
      libraryadmin: PropTypes.bool,
      billingadmin: PropTypes.bool,
    }),
  }).isRequired,
  avatarInitialsFromString: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleUpdateRow: PropTypes.func.isRequired,
  handleResetPasswordBtnClick: PropTypes.func.isRequired,
  handleResetMFABtnClick: PropTypes.func.isRequired,
  handleToggleRole: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.func.isRequired,
  })).isRequired,
}
const Row = memo(({
  row,
  avatarInitialsFromString,
  handleDeleteRow,
  handleUpdateRow,
  handleResetPasswordBtnClick,
  handleResetMFABtnClick,
  handleToggleRole,
  permissions,
}) => (
  <>
    <Tooltip title={row.displayName}>
      <StyledTableCell>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{
            '@media (max-width: 1750px)': {
              display: 'none',
            },
          }}
          >
            <Avatar style={{
              marginRight: '12px',
              alignItems: 'center',
              backgroundColor: row.avatarColor,
            }}
            >
              {avatarInitialsFromString(row.displayName)}
            </Avatar>
          </Box>
          <RowText>{row.displayName}</RowText>
        </div>
      </StyledTableCell>
    </Tooltip>
    <Tooltip title={row.userPrincipalName}>
      <StyledTableCell>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <RowText>{row.userPrincipalName}</RowText>
        </div>
      </StyledTableCell>
    </Tooltip>
    <Tooltip title={row.email}>
      <StyledTableCell>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <RowText>{row.email}</RowText>
        </div>
      </StyledTableCell>
    </Tooltip>

    <TableCell key="options" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
      <Tooltip title="Delete">
        <Button
          sx={{ minWidth: '24px' }}
          aria-label="delete row"
          variant="text"
          onClick={() => handleDeleteRow(row.userPrincipalName)}
        >
          <DeleteOutlineIcon />
        </Button>

      </Tooltip>
      <Tooltip title="Edit">
        <Button
          sx={{ minWidth: '24px' }}
          aria-label="update row"
          variant="text"
          onClick={() => handleUpdateRow(row.userPrincipalName)}
        >
          <UpdateOutlineIcon />
        </Button>

      </Tooltip>
      <Tooltip title="Reset Password">
        <Button
          sx={{ minWidth: '24px' }}
          aria-label="reset password"
          variant="text"
          onClick={() => handleResetPasswordBtnClick(row.userPrincipalName, row.displayName, row.email)}
        >
          <VpnKeyIcon />
        </Button>

      </Tooltip>
      <Tooltip title="Reset Authentication Methods">
        <Button
          sx={{ minWidth: '24px' }}
          aria-label="reset authentication methods"
          variant="text"
          onClick={() => handleResetMFABtnClick(row.userPrincipalName)}
        >
          <LockPersonIcon />
        </Button>
      </Tooltip>
    </TableCell>

    {permissions.map((p) => (
      <StyledTableCell>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        }}
        >
          {row.toggleInProgress[p.id] ? <CircularProgress size="24px" />
            : (
              <Checkbox
                onChange={(e) => handleToggleRole(row.userPrincipalName, p.id, e.target.checked)}
                checked={row[p.id]}
                disabled={p.disabled(row)}
              />
            )}
        </div>
      </StyledTableCell>
    ))}

  </>

), (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps))
Row.propTypes = rowPropTypes

/**
 * Pod owners use this page to add and remove administrators for their Pod. System administrators
 * can add and give permissions to Pod Owners, Library and Billing Administrators
 */
function AdministratorsScreen({
  user, askForConfirmationListener, datalabFacade, showSnackbarSuccess,
}) {
  const columns = [
    { id: 'displayName', label: 'Name' },
    { id: 'userPrincipalName', label: 'Username' },
    { id: 'email', label: 'Email' }]
  const permissions = [...[
    {
      id: 'readonly', label: 'Reader', disabled: () => true,
    },
    {
      id: 'admin',
      label: 'Project Admin',
      disabled: (row) => !row.readonly && !row.admin,
    },
    {
      id: 'dataadmin',
      label: 'Data Admin',
      disabled: (row) => !row.readonly && !row.admin,
    },
    {
      id: 'useradmin',
      label: 'User Admin',
      disabled: (row) => !row.readonly && !row.admin,
    },
    {
      id: 'podowner',
      label: 'Owner',
      disabled: () => !user.roles.includes(Role.SYSADMIN),
    },
  ], ...(user.pod === 'ABS' ? [
    {
      id: 'billingadmin',
      label: 'Billing Admin',
      disabled: () => !user.roles.includes(Role.SYSADMIN),
    },
    {
      id: 'libraryadmin',
      label: 'Library Admin',
      disabled: () => !user.roles.includes(Role.SYSADMIN),
    },
  ] : [])]

  const defaultPermissionFilters = (selected) => Object.fromEntries(permissions
    .map((pc) => ([pc.id, selected])))

  const [error, setError] = useState(null)
  const [tempPassword, setTempPassword] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('userPrincipalName')
  const [admins, setAdmins] = useState([])
  const [loading, setLoading] = useState(true)
  const [permissionFilters, setPermissionFilters] = useState(defaultPermissionFilters(true))
  const [permissionFiltersSelectAll, setPermissionFiltersSelectAll] = useState(true)
  const [quickSearchText, setQuickSearchText] = useState('')
  const [submittedQuickSearchText, setSubmittedQuickSearchText] = useState('')
  const quickSearchRef = createRef()
  const navigate = useNavigate()

  if (error) {
    throw error
  }

  const avatarInitialsFromString = useCallback((s) => {
    const stringParts = s.split(' ')
    const part1 = stringParts[0] ? stringParts[0].charAt(0) : ''
    const part2 = stringParts[1] ? stringParts[1].charAt(0) : ''
    return (part1 + part2).toUpperCase()
  })

  const avatarColorFromString = (str) => {
    let hash = 0
    const strArray = str.split('')
    strArray.forEach((char) => {
      hash += char.charCodeAt(0)
    })
    const h = hash % 360
    return `hsl(${h}, 50%, 50%)`
  }

  const loadData = async () => {
    setLoading(true)
    const rawData = await datalabFacade.getPodAdmins(user.pod)
    const formattedData = rawData.map((r) => ({
      ...r,
      avatarColor: avatarColorFromString(r.displayName),
      toggleInProgress: { ...Object.fromEntries(permissions.map((p) => [p.id, false])) },
    }))
    setAdmins(formattedData)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const filteredAdmins = (useMemo(() => {
    let filtered = admins
    filtered = filterByPermissionFilters(filtered, permissionFilters)
    filtered = filterByQuickSearchText(filtered, submittedQuickSearchText)
    return filtered
  }, [permissionFilters, submittedQuickSearchText, admins]) || [])

  const handleDeleteRow = useCallback((upn) => {
    askForConfirmationListener(
      `Are you sure you want to delete ${upn}? WARNING: this action will completely delete the user account`,
      async () => {
        await datalabFacade.deleteADUser(upn, user.pod)
        showSnackbarSuccess(`Delete Administrator (${upn}) succeeded`)
        setAdmins((prevItems) => prevItems.filter((e) => e.userPrincipalName !== upn))
      }
    )
  }, [])

  const handleUpdateRow = useCallback((upn) => {
    navigate(`/administrators/${upn}/edit`)
  })

  const handleToggleRole = useCallback(async (userPrincipalName, role, checked) => {
    setAdmins((prevItems) => prevItems.map((a) => (
      {
        ...a,
        toggleInProgress: {
          ...a.toggleInProgress,
          [role]: a.userPrincipalName === userPrincipalName ? true : a.toggleInProgress[role],
        },
      }
    )))
    try {
      await datalabFacade.togglePodAdminRole(userPrincipalName, role, checked)
    } catch (err) {
      setError(err)
    }
    setAdmins((prevItems) => prevItems.map((a) => (
      {
        ...a,
        [role]: a.userPrincipalName === userPrincipalName ? !a[role] : a[role],
        toggleInProgress: {
          ...a.toggleInProgress,
          [role]: a.userPrincipalName === userPrincipalName ? false : a.toggleInProgress[role],
        },
      }
    )))
  }, [])

  const handleSubmitQuickSearch = () => {
    setSubmittedQuickSearchText(quickSearchText)
    quickSearchRef.current.focus()
  }

  const handleCancelQuickSearch = () => {
    setQuickSearchText('')
    setSubmittedQuickSearchText('')
    quickSearchRef.current.focus()
  }

  const handleTogglePermissionFilter = (permissionId) => {
    const updatedPermissionFilters = {
      ...permissionFilters,
      [permissionId]: !permissionFilters[permissionId],
    }
    setPermissionFilters(updatedPermissionFilters)
  }

  const handleSelectAllPermissionFilters = () => {
    setPermissionFiltersSelectAll(!permissionFiltersSelectAll)
    setPermissionFilters(defaultPermissionFilters(!permissionFiltersSelectAll))
  }

  const handleNewBtnClick = () => {
    navigate('/administrators/new')
  }

  const handleResetPasswordBtnClick = useCallback((upn, displayName, email) => {
    const adminDetails = {
      upn,
      tempPassword: generateInitialAzureAdPassword(),
      email,
      name: displayName,
      podId: user.pod,
      admin: true,
    }
    askForConfirmationListener(
      `The Administrator '${adminDetails.upn}' will be assigned a temporary password which must be changed on next sign in. Are you sure you want to reset this Administrator's password?`,
      async () => {
        await datalabFacade.resetUserPassword(adminDetails)
        showSnackbarSuccess(`Reset password for (${adminDetails.upn}) succeeded`)
        setTempPassword(adminDetails.tempPassword)
        setOpenDialog(true)
      }
    )
  }, [])

  const handleResetMFABtnClick = useCallback((upn) => {
    askForConfirmationListener(
      `The user '${upn}' will be required to reregister an MFA method and password recovery email/phone. Are you sure you want to reset this user's authentication methods?`,
      async () => {
        await datalabFacade.resetADUserMFA(upn)
        showSnackbarSuccess(`Reset MFA for (${upn}) succeeded`)
      }
    )
  }, [])

  const handleExportToCsv = () => {
    const csvExportOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Pod Administrators',
      filename: 'podAdministrators',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }

    const csvExporter = new ExportToCsv(csvExportOptions)

    csvExporter.generateCsv(filteredAdmins.map((admin) => ({
      'Display Name': admin.displayName,
      Username: admin.userPrincipalName,
      Email: admin.email,
      ...Object.fromEntries(permissions.map((pc) => [pc.label, admin[pc.id]])),
    })))
  }

  if (openDialog) {
    return (
      <TempPasswordDialog
        dialogType="resetPassword"
        open={openDialog}
        tempPassword={tempPassword}
        callback={() => setOpenDialog(false)}
      />
    )
  }

  return (
    <>
      <div className="action-button-container">
        <Button variant="contained" color="primary" onClick={handleNewBtnClick}>
          New Administrator&nbsp;&nbsp;
          <i className="fas fa-plus-circle" />
        </Button>

      </div>
      <>
        {/* Quick filter and refresh/export/rows */}
        <div style={{
          lineHeight: '60px', height: '60px', width: '100%', padding: '0px 20px 0px 20px',
        }}
        >
          <div style={{ margin: '0px 10px 0px 10px', width: '400px', display: 'inline-block' }}>
            <TextField
              fullWidth
              disabled={loading}
              id="quick-search-box"
              label="Quick Search"
              inputRef={quickSearchRef}
              value={quickSearchText}
              onChange={(e) => setQuickSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmitQuickSearch()
                }
              }}
              InputProps={{
                endAdornment:
  <>
    {quickSearchText !== '' && (
    <IconButton
      style={{
        padding: '5px',
      }}
      aria-label="cancel quick search"
      onClick={handleCancelQuickSearch}
      size="large"
    >
      <CancelIcon />
    </IconButton>
    )}
    <IconButton
      style={{
        padding: '5px',
      }}
      aria-label="submit quick search"
      onClick={handleSubmitQuickSearch}
      size="large"
    >
      <SearchIcon />
    </IconButton>
  </>,
              }}
            />
          </div>
          <Tooltip title="Export to CSV">
            <Button
              aria-label="export to csv"
              color="primary"
              size="small"
              variant="text"
              style={{ height: '60px', float: 'right', display: 'inline-block' }}
              onClick={handleExportToCsv}
            >
              Export&nbsp;&nbsp;
              <i className="fas fa-file-download" />
            </Button>
          </Tooltip>
          <Tooltip
            title="Refresh table data"
            style={{
              display: 'inline-block', float: 'right', height: '60px', width: '100px',
            }}
          >
            <Button
              aria-label="refresh"
              variant="text"
              color="primary"
              size="small"
              onClick={loadData}
            >
              Refresh&nbsp;&nbsp;
              <i className="fas fa-sync-alt" />
            </Button>
          </Tooltip>
          {loading
              && <CircularProgress size="24px" style={{ float: 'right', marginTop: '15px' }} />}
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              width: '150px',
              ml: 2,
            }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            Total:
            {' '}
            {filteredAdmins?.length ?? 0}
          </Typography>
          <Typography sx={{ mr: 1, ml: 'auto' }} component="p">Filter:</Typography>
          <FormGroup row>
            <FormControlLabel
              key="select-all"
              control={(
                <Checkbox
                  disabled={loading}
                  checked={permissionFiltersSelectAll}
                  onChange={handleSelectAllPermissionFilters}
                  name="select-all"
                />
                  )}
              label="Select All"
            />
            {permissions.map((pc) => (
              <FormControlLabel
                key={pc.id}
                control={(
                  <Checkbox
                    disabled={loading}
                    checked={permissionFilters[pc.id]}
                    onChange={() => handleTogglePermissionFilter(pc.id)}
                    name={pc.id}
                  />
                    )}
                label={pc.label}
              />
            ))}

          </FormGroup>
        </Box>
        {loading
          ? (
            <>
              {
                [...Array(15)].map((n, index) => (
                  <Skeleton
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    height={50}
                    style={{ margin: '20px' }}
                  />
                ))
              }
            </>
          )
          : (
            <TableVirtuoso
              data={filteredAdmins?.sort(getComparator(order, orderBy))}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => Headers({
                user,
                columns,
                permissions,
                orderBy,
                order,
                setOrder,
                setOrderBy,
              })}
                // eslint-disable-next-line react/no-unstable-nested-components
              itemContent={(_index, row) => (
                <Row
                  row={row}
                  avatarInitialsFromString={avatarInitialsFromString}
                  handleDeleteRow={handleDeleteRow}
                  handleUpdateRow={handleUpdateRow}
                  handleResetPasswordBtnClick={handleResetPasswordBtnClick}
                  handleResetMFABtnClick={handleResetMFABtnClick}
                  handleToggleRole={handleToggleRole}
                  user={user}
                  permissions={permissions}
                />
              )}
            />
          )}
      </>

    </>
  )
}

AdministratorsScreen.propTypes = propTypes
export default wrap(AdministratorsScreen)
